import gql from 'graphql-tag';

const GET_EVENTS_USERS_STATISTICS = gql`
  query eventsUsersStatistics($company: String!, $start: String!, $end: String!) {
    eventsUsersStatistics(company: $company, start: $start, end: $end) {
      stats {
        event_maker_id
        event_id
        event_title
        event_location
        event_site
        event_date
        user_id
        user_name
        user_lastname
        user_email
      }
    }
  }
`;

export default GET_EVENTS_USERS_STATISTICS;